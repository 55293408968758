/**
 * @generated SignedSource<<ed919fe3e5c6d07708893a93c03bfba0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type exams_proctoring$data = {
  readonly examVersions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      } | null;
    } | null> | null;
  };
  readonly id: string;
  readonly name: string;
  readonly registrations: ReadonlyArray<{
    readonly currentPin: string | null;
    readonly examVersion: {
      readonly id: string;
    };
    readonly id: string;
    readonly room: {
      readonly id: string;
    } | null;
    readonly user: {
      readonly displayName: string;
      readonly id: string;
    };
  }>;
  readonly rooms: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"exams_anomalies" | "exams_messages" | "exams_pins">;
  readonly " $fragmentType": "exams_proctoring";
};
export type exams_proctoring$key = {
  readonly " $data"?: exams_proctoring$data;
  readonly " $fragmentSpreads": FragmentRefs<"exams_proctoring">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "examVersions"
        ]
      }
    ]
  },
  "name": "exams_proctoring",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "exams_anomalies"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "exams_messages"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "exams_pins"
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": "examVersions",
      "args": null,
      "concreteType": "ExamVersionConnection",
      "kind": "LinkedField",
      "name": "__Exam_examVersions_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamVersionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExamVersion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Registration",
      "kind": "LinkedField",
      "name": "registrations",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Room",
          "kind": "LinkedField",
          "name": "room",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamVersion",
          "kind": "LinkedField",
          "name": "examVersion",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currentPin",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Room",
      "kind": "LinkedField",
      "name": "rooms",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Exam",
  "abstractKey": null
};
})();

(node as any).hash = "ad375a9d2288242fcd7270e6d75165c6";

export default node;
