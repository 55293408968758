/**
 * @generated SignedSource<<9e0576ded3a10b7e4d8b27f483149d55>>
 * @relayHash b8f8acbd6290a8c01a3b16bf8e609fa0
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b8f8acbd6290a8c01a3b16bf8e609fa0

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type examsProctorQuery$variables = {
  examId: string;
  skipCourse: boolean;
};
export type examsProctorQuery$data = {
  readonly exam: {
    readonly course?: {
      readonly id: string;
      readonly title: string;
    };
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"exams_proctoring">;
  };
};
export type examsProctorQuery = {
  response: examsProctorQuery$data;
  variables: examsProctorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "examId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skipCourse"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "examId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "condition": "skipCourse",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100000
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v14 = [
  (v2/*: any*/),
  (v4/*: any*/)
],
v15 = [
  (v2/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Room",
  "kind": "LinkedField",
  "name": "room",
  "plural": false,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "ExamVersion",
  "kind": "LinkedField",
  "name": "examVersion",
  "plural": false,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Registration",
  "kind": "LinkedField",
  "name": "registration",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v16/*: any*/),
    (v17/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v19 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "examsProctorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Exam",
        "kind": "LinkedField",
        "name": "exam",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "exams_proctoring"
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "examsProctorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Exam",
        "kind": "LinkedField",
        "name": "exam",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "AnomalyConnection",
            "kind": "LinkedField",
            "name": "anomalies",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AnomalyEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Anomaly",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reason",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "priorAnomalyCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Registration",
                        "kind": "LinkedField",
                        "name": "registration",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "final",
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": "anomalies(first:20)"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "Exam_anomalies",
            "kind": "LinkedHandle",
            "name": "anomalies"
          },
          {
            "alias": null,
            "args": (v12/*: any*/),
            "concreteType": "VersionAnnouncementConnection",
            "kind": "LinkedField",
            "name": "versionAnnouncements",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VersionAnnouncementEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VersionAnnouncement",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v6/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExamVersion",
                        "kind": "LinkedField",
                        "name": "examVersion",
                        "plural": false,
                        "selections": (v14/*: any*/),
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": "versionAnnouncements(first:100000)"
          },
          {
            "alias": null,
            "args": (v12/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "Exam_versionAnnouncements",
            "kind": "LinkedHandle",
            "name": "versionAnnouncements"
          },
          {
            "alias": null,
            "args": (v12/*: any*/),
            "concreteType": "RoomAnnouncementConnection",
            "kind": "LinkedField",
            "name": "roomAnnouncements",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RoomAnnouncementEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoomAnnouncement",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v6/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Room",
                        "kind": "LinkedField",
                        "name": "room",
                        "plural": false,
                        "selections": (v14/*: any*/),
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": "roomAnnouncements(first:100000)"
          },
          {
            "alias": null,
            "args": (v12/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "Exam_roomAnnouncements",
            "kind": "LinkedHandle",
            "name": "roomAnnouncements"
          },
          {
            "alias": null,
            "args": (v12/*: any*/),
            "concreteType": "ExamAnnouncementConnection",
            "kind": "LinkedField",
            "name": "examAnnouncements",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExamAnnouncementEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExamAnnouncement",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v6/*: any*/),
                      (v13/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": "examAnnouncements(first:100000)"
          },
          {
            "alias": null,
            "args": (v12/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "Exam_examAnnouncements",
            "kind": "LinkedHandle",
            "name": "examAnnouncements"
          },
          {
            "alias": null,
            "args": (v12/*: any*/),
            "concreteType": "StudentQuestionConnection",
            "kind": "LinkedField",
            "name": "studentQuestions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StudentQuestionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudentQuestion",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v6/*: any*/),
                      (v18/*: any*/),
                      (v13/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": "studentQuestions(first:100000)"
          },
          {
            "alias": null,
            "args": (v12/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "Exam_studentQuestions",
            "kind": "LinkedHandle",
            "name": "studentQuestions"
          },
          {
            "alias": null,
            "args": (v12/*: any*/),
            "concreteType": "MessageConnection",
            "kind": "LinkedField",
            "name": "messages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MessageEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Message",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v13/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "sender",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isMe",
                            "storageKey": null
                          },
                          (v7/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v18/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": "messages(first:100000)"
          },
          {
            "alias": null,
            "args": (v12/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "Exam_messages",
            "kind": "LinkedHandle",
            "name": "messages"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Registration",
            "kind": "LinkedField",
            "name": "registrations",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currentPin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pinValidated",
                "storageKey": null
              },
              (v16/*: any*/),
              (v17/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": (v19/*: any*/),
            "concreteType": "ExamVersionConnection",
            "kind": "LinkedField",
            "name": "examVersions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExamVersionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExamVersion",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": "examVersions(first:100)"
          },
          {
            "alias": null,
            "args": (v19/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "Exam_examVersions",
            "kind": "LinkedHandle",
            "name": "examVersions"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Room",
            "kind": "LinkedField",
            "name": "rooms",
            "plural": true,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "b8f8acbd6290a8c01a3b16bf8e609fa0",
    "metadata": {},
    "name": "examsProctorQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "3d78d7b5439a8a79c8ad1dfabfcca251";

export default node;
