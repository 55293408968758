/**
 * @generated SignedSource<<ec7215bb01f44667b1c3119c77e8dfe0>>
 * @relayHash ec8281cb4323b871ba8fc764ef8203d8
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ec8281cb4323b871ba8fc764ef8203d8

import { ConcreteRequest, Query } from 'relay-runtime';
export type CourseRole = "NONE" | "PROCTOR" | "PROFESSOR" | "STUDENT";
export type gradingOneRoleQuery$variables = {
  examId: string;
};
export type gradingOneRoleQuery$data = {
  readonly me: {
    readonly role: CourseRole;
  };
};
export type gradingOneRoleQuery = {
  response: gradingOneRoleQuery$data;
  variables: gradingOneRoleQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "examId"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "examId",
      "variableName": "examId"
    }
  ],
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "gradingOneRoleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "gradingOneRoleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ec8281cb4323b871ba8fc764ef8203d8",
    "metadata": {},
    "name": "gradingOneRoleQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "e927627ee646580d0dabd65501e91b36";

export default node;
