/**
 * @generated SignedSource<<8de5c54b662d41661e1eea81be9b7502>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type gradingLock$data = {
  readonly grader: {
    readonly displayName: string;
  } | null;
  readonly pnum: number;
  readonly qnum: number;
  readonly registration: {
    readonly exam: {
      readonly id: string;
    };
    readonly id: string;
    readonly user: {
      readonly displayName: string;
    };
  };
  readonly " $fragmentType": "gradingLock";
};
export type gradingLock$key = {
  readonly " $data"?: gradingLock$data;
  readonly " $fragmentSpreads": FragmentRefs<"gradingLock">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "gradingLock",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "qnum",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pnum",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Registration",
      "kind": "LinkedField",
      "name": "registration",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Exam",
          "kind": "LinkedField",
          "name": "exam",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "grader",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "GradingLock",
  "abstractKey": null
};
})();

(node as any).hash = "79d6903fb92fa70c7764a4289423adc2";

export default node;
