/**
 * @generated SignedSource<<f4e5f3314ed0342e1a81e1c706d42955>>
 * @relayHash 2e9dd75ed1ab39a5696baf6712893261
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2e9dd75ed1ab39a5696baf6712893261

import { ConcreteRequest, Query } from 'relay-runtime';
export type CourseRole = "NONE" | "PROCTOR" | "PROFESSOR" | "STUDENT";
export type submissionsRoleQuery$variables = {
  examId: string;
};
export type submissionsRoleQuery$data = {
  readonly me: {
    readonly id: string;
    readonly role: CourseRole;
  };
};
export type submissionsRoleQuery = {
  response: submissionsRoleQuery$data;
  variables: submissionsRoleQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "examId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "examId",
            "variableName": "examId"
          }
        ],
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "submissionsRoleQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "submissionsRoleQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "2e9dd75ed1ab39a5696baf6712893261",
    "metadata": {},
    "name": "submissionsRoleQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "2a09ca7941070273acd9254c7a57d7cb";

export default node;
