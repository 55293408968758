/**
 * @generated SignedSource<<41ab4cc09c7861e84e788df6cd84b5b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type gradingMyGrading$data = {
  readonly examVersions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly finished: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly pnum: number;
              readonly qnum: number;
              readonly registration: {
                readonly id: string;
                readonly user: {
                  readonly displayName: string;
                };
              };
              readonly updatedAt: string;
            } | null;
          } | null> | null;
        };
        readonly id: string;
        readonly inProgress: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly pnum: number;
              readonly qnum: number;
              readonly registration: {
                readonly id: string;
                readonly user: {
                  readonly displayName: string;
                };
              };
              readonly updatedAt: string;
            } | null;
          } | null> | null;
        };
        readonly name: string;
        readonly qpPairs: ReadonlyArray<{
          readonly pnum: number;
          readonly qnum: number;
        }>;
      } | null;
    } | null> | null;
  };
  readonly id: string;
  readonly " $fragmentType": "gradingMyGrading";
};
export type gradingMyGrading$key = {
  readonly " $data"?: gradingMyGrading$data;
  readonly " $fragmentSpreads": FragmentRefs<"gradingMyGrading">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "qnum",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pnum",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GradingLockEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GradingLock",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Registration",
            "kind": "LinkedField",
            "name": "registration",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "gradingMyGrading",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamVersionConnection",
      "kind": "LinkedField",
      "name": "examVersions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamVersionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExamVersion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "QpPair",
                  "kind": "LinkedField",
                  "name": "qpPairs",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": "inProgress",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "graderCurrentUser",
                      "value": true
                    }
                  ],
                  "concreteType": "GradingLockConnection",
                  "kind": "LinkedField",
                  "name": "gradingLocks",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": "gradingLocks(graderCurrentUser:true)"
                },
                {
                  "alias": "finished",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "completedByCurrentUser",
                      "value": true
                    }
                  ],
                  "concreteType": "GradingLockConnection",
                  "kind": "LinkedField",
                  "name": "gradingLocks",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": "gradingLocks(completedByCurrentUser:true)"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Exam",
  "abstractKey": null
};
})();

(node as any).hash = "28aa1564fe9f6a65fea287bd8c350b57";

export default node;
